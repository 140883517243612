<template>
  <b-modal
    title="Изменить тему"
    hide-header-close
    body-class="position-static"
    :id="modalId"
    @ok="handleOk"
    @hide="handleHide"
  >
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        class="mr-auto"
        variant="outline-danger"
        @click="handleDelete"
      >
        Удалить
      </b-button>
      <b-button
        :disabled="isLoading"
        @click="cancel"
      >
        Отменить
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="ok"
      >
        Изменить
      </b-button>
    </template>
    <b-overlay :show="isLoading" rounded>
      <b-alert
        :show="Object.keys(serverError).length > 0"
        variant="danger"
      >
        Ошибка {{ serverError.description }}
        <template
          v-if="serverError.status !== 422"
        >
          {{ serverError.status }}
        </template>
      </b-alert>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          label="Slug"
          label-for="slug-input"
        >
          <b-form-input
            id="slug-input"
            v-model="form.slug"
            :state="$v.form.slug.$dirty
              ? !$v.form.slug.$error
              : null"
            aria-describedby="slug-live-feedback"
          ></b-form-input>
          <b-form-invalid-feedback
            id="slug-live-feedback"
          >
            <div v-if="!$v.form.slug.required">
              Поле обязательно для заполнения
            </div>
            <template v-if="!$v.form.slug.serverValidationError">
              <div
                v-for="(error, index) in serverValidationErrors.slug"
                :key="`slug-serverValidationError-${index}`"
              >
                {{ error }}
              </div>
          </template>
          </b-form-invalid-feedback>
        </b-form-group>
        <h5>Название</h5>
        <template v-if="languages">
          <b-form-group
            v-for="language in languages"
            :key="language.slug"
            :label="language.name"
            :label-for="`name[${language.slug}]`"
          >
            <b-form-input
              :id="`title[${language.slug}]`"
              v-model="form.data.title[language.slug]"
              :state="$v.form.data.title[language.slug].$dirty
                ? !$v.form.data.title[language.slug].$error
                : null"
              :aria-describedby="`title-${language.slug}-live-feedback`"
            ></b-form-input>
            <b-form-invalid-feedback
              :id="`title-${language.slug}-live-feedback`"
            >
              <div v-if="!$v.form.data.title[language.slug].required">
                Поле обязательно для заполнения
              </div>
              <template v-if="!$v.form.data.title[language.slug].serverValidationError">
                <div
                  v-for="(error, index) in serverValidationErrors.data.title[language.slug]"
                  :key="`title-${language.slug}-serverValidationError-${index}`"
                >
                  {{ error }}
                </div>
            </template>
            </b-form-invalid-feedback>
          </b-form-group>
        </template>
      </b-form>
    </b-overlay>
  </b-modal>
</template>

<script>
import flow from 'lodash/flow';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import cloneDeep from 'lodash/cloneDeep';
import { validationMixin } from 'vuelidate';
import {
  required,
} from 'vuelidate/lib/validators';

export default {
  mixins: [
    validationMixin,
  ],
  data() {
    return {
      isLoading: false,
      form: {
        slug: undefined,
        data: {
          title: {},
        },
      },
      serverValidationErrors: {},
      serverError: {},
    };
  },
  props: {
    modalId: {
      required: true,
    },
    languages: {
      required: true,
    },
    topic: {
      required: true,
    },
  },
  watch: {
    topic(value) {
      this.form = cloneDeep(value);
    },
    form: {
      deep: true,
      handler() {
        this.serverValidationErrors = {};
        this.serverError = {};
      },
    },
  },
  validations() {
    return {
      form: {
        slug: {
          required,
        },
        data: {
          title: {
            ...flow(
              (languages) => keyBy(languages, 'slug'),
              (languages) => mapValues(languages, (language) => ({
                required,
                serverValidationError: () => !(this.serverValidationErrors.data
                  && this.serverValidationErrors.data.title
                  && this.serverValidationErrors.data.title[language.slug]),
              })),
            )(this.languages),
          },
        },
      },
    };
  },
  methods: {
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleHide(bvModalEvt) {
      if (this.isLoading) {
        bvModalEvt.preventDefault();
      }
    },
    async handleSubmit() {
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      try {
        this.isLoading = true;

        const { data: { topic } } = await this.axios.put(`/blog/topics/${this.topic.id}`, this.form);

        if (!topic) {
          throw new Error('Ошибка сервера');
        }

        this.$emit('updated', topic);

        this.$v.form.$reset();

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: e.response && e.response.status === 422 ? 'валидации' : 'сервера',
        };
        this.serverValidationErrors = e.response && e.response.data && e.response.data.errors
          ? e.response.data.errors
          : {};
      }
      this.isLoading = false;
    },
    async handleDelete() {
      try {
        this.isLoading = true;

        const {
          data: { topic },
        } = await this.axios.delete(`/blog/topics/${this.topic.id}`);

        if (!topic) {
          throw new Error('Ошибка сервера');
        }

        this.form = {
          slug: undefined,
          data: {
            title: {},
          },
        };

        this.$emit('deleted', topic);
        // this.$v.form.$reset();
        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId);
        });
      } catch (e) {
        this.serverError = {
          status: e.response ? e.response.status : undefined,
          description: 'сервера',
        };
      }
      this.isLoading = false;
    },
  },
};
</script>
