var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"title":"Изменить тему","hide-header-close":"","body-class":"position-static","id":_vm.modalId},on:{"ok":_vm.handleOk,"hide":_vm.handleHide},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{staticClass:"mr-auto",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_vm._v(" Удалить ")]),_c('b-button',{attrs:{"disabled":_vm.isLoading},on:{"click":cancel}},[_vm._v(" Отменить ")]),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":ok}},[_vm._v(" Изменить ")])]}}])},[_c('b-overlay',{attrs:{"show":_vm.isLoading,"rounded":""}},[_c('b-alert',{attrs:{"show":Object.keys(_vm.serverError).length > 0,"variant":"danger"}},[_vm._v(" Ошибка "+_vm._s(_vm.serverError.description)+" "),(_vm.serverError.status !== 422)?[_vm._v(" "+_vm._s(_vm.serverError.status)+" ")]:_vm._e()],2),_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Slug","label-for":"slug-input"}},[_c('b-form-input',{attrs:{"id":"slug-input","state":_vm.$v.form.slug.$dirty
            ? !_vm.$v.form.slug.$error
            : null,"aria-describedby":"slug-live-feedback"},model:{value:(_vm.form.slug),callback:function ($$v) {_vm.$set(_vm.form, "slug", $$v)},expression:"form.slug"}}),_c('b-form-invalid-feedback',{attrs:{"id":"slug-live-feedback"}},[(!_vm.$v.form.slug.required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.slug.serverValidationError)?_vm._l((_vm.serverValidationErrors.slug),function(error,index){return _c('div',{key:("slug-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1),_c('h5',[_vm._v("Название")]),(_vm.languages)?_vm._l((_vm.languages),function(language){return _c('b-form-group',{key:language.slug,attrs:{"label":language.name,"label-for":("name[" + (language.slug) + "]")}},[_c('b-form-input',{attrs:{"id":("title[" + (language.slug) + "]"),"state":_vm.$v.form.data.title[language.slug].$dirty
              ? !_vm.$v.form.data.title[language.slug].$error
              : null,"aria-describedby":("title-" + (language.slug) + "-live-feedback")},model:{value:(_vm.form.data.title[language.slug]),callback:function ($$v) {_vm.$set(_vm.form.data.title, language.slug, $$v)},expression:"form.data.title[language.slug]"}}),_c('b-form-invalid-feedback',{attrs:{"id":("title-" + (language.slug) + "-live-feedback")}},[(!_vm.$v.form.data.title[language.slug].required)?_c('div',[_vm._v(" Поле обязательно для заполнения ")]):_vm._e(),(!_vm.$v.form.data.title[language.slug].serverValidationError)?_vm._l((_vm.serverValidationErrors.data.title[language.slug]),function(error,index){return _c('div',{key:("title-" + (language.slug) + "-serverValidationError-" + index)},[_vm._v(" "+_vm._s(error)+" ")])}):_vm._e()],2)],1)}):_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }