<template>
  <b-container>
    <blog-topic-create
      modal-id="blogTopicCreate"
      @created="handleCreated"
      :languages="languages.value"
    />
    <blog-topic-edit
      modal-id="blogTopicEdit"
      :languages="languages.value"
      :topic="selectedTopic"
      @updated="handleUpdated"
      @deleted="handleDeleted"
    />
    <h1
      class="mt-2"
    >
      Темы
       <b-button v-b-modal.blogTopicCreate>
        Добавить
      </b-button>
    </h1>

    <b-table
      stacked="lg"
      responsive small striped borderless
      :items="items"
      :fields="fields"
      :busy="!topics.request.isSuccess"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <template v-if="topics.request.isLoading">
            <b-spinner class="align-middle"></b-spinner>
          </template>
          <strong class="ml-2">
            <template v-if="topics.request.isLoading">
              Загрузка тем...
            </template>
            <template v-else-if="topics.request.isError">
              Ошибка загрузки тем. Повтор через {{ topicRequestDelay }} сек.
            </template>
            <template v-else-if="topics.request.isFatalError">
              Ошибка. Попробуйте позже.
            </template>
          </strong>
        </div>
      </template>
      <template v-slot:cell(show_details)="row">
        <b-button
          size="sm"
          v-b-modal.blogTopicEdit
          @click="() => {
            selectedTopic = row.item;
          }"
        >
          Изменить
        </b-button>
      </template>
    </b-table>
  </b-container>
</template>

<script>
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';
import { mapState } from 'vuex';

import BlogTopicCreate from './components/BlogTopicCreate.vue';
import BlogTopicEdit from './components/BlogTopicEdit.vue';

export default {
  components: {
    BlogTopicCreate,
    BlogTopicEdit,
  },
  data() {
    return {
      selectedTopic: undefined,
      topics: {
        request: {},
        value: undefined,
      },
      fields: [
        {
          key: 'slug',
          label: 'Slug',
        },
        {
          key: 'title',
          label: 'Название',
        },
        {
          key: 'show_details',
          label: 'Действие',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      timestamp: ({ timestamp }) => timestamp,
      languages: ({ language: { languages: value, current, request } }) => ({
        current,
        value,
        request,
      }),
    }),
    items() {
      if (!this.topics.request.isSuccess) {
        return [];
      }
      if (!this.languages.current) {
        return this.topics.value;
      }
      return map(this.topics.value, (topic) => ({
        ...topic,
        title: topic.data.title ? topic.data.title[this.languages.current] : '',
      }));
    },
    topicRequestDelay() {
      if (!this.topics.request.isError) {
        return 0;
      }
      const ms = this.topics.request.delay - (this.timestamp - this.topics.request.lastRequestTime);
      return Math.floor(ms / 1000) + 1;
    },
  },
  methods: {
    handleCreated(topic) {
      this.topics.value.push(topic);
    },
    handleUpdated(topic) {
      this.topics.value.splice(
        findIndex(this.topics.value, { id: topic.id }),
        1,
        topic,
      );
    },
    handleDeleted(topic) {
      this.topics.value = reject(this.topics.value, {
        id: topic.id,
      });
    },
  },
  async beforeMount() {
    try {
      const { data: { topics } } = await this.axios.get('blog/topics', {
        'axios-retry': {
          retries: 5,
          beforeTry: () => {
            this.topics.request = {
              isLoading: true,
              isError: false,
            };
          },
          afterTry: ({ delay, lastRequestTime }) => {
            this.topics.request = {
              isLoading: false,
              isError: true,
              delay,
              lastRequestTime,
            };
          },
        },
      });
      this.topics.request = {
        isSuccess: true,
      };
      this.topics.value = topics;
    } catch (e) {
      this.topics.request = {
        isFatalError: true,
      };
    }
  },
};
</script>
